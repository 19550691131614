<template>
  <div>
    <div
      v-if="!loading"
      style="
        height: 100vh;
        contain: content;
        background: var(--v-greyRaised-base) !important;
      "
    >
      <div
        style="
          height: 36px;
          width: 100%;
          display: flex;
          align-items: center;
          background-color: var(--v-secondary-base) !important;
          background: var(--v-secondary-base) !important;
        "
      >
        <span style="font-size: 16px" class="ml-2"
          ><b>Products & Services</b></span
        >
      </div>
    </div>
    <div
      v-else
      style="
        height: 100%;
        padding-top: 0;
        margin-top: 0;
        background: transparent;
      "
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>
    <script>
    export default {
        data: ()=>({
            loading: true
        })
    }
</script>